class Call {
  getGuid() {
    return window.location.pathname.substr(1);
  }

  async start() {
    this.guid = this.getGuid();
    if (!this.guid) {
      this.store.commit("setAlert", {
        // title: "Что-то пошло не так",
        // details: "Инициализация звонка с пустым идентификатором",
        title: "Something gone wrong",
        details: "Empty call id",
        icon: "mdi-cancel",
      });
    }

    await this.captureLocalDevices();
    await this.configureLocalStream();

    // subscribe to device changing in browser
    navigator.mediaDevices.ondevicechange = async () => {
      console.log("device list was changed so rescan it");
      await this.captureLocalDevices();
      this.checkConnectedSources();
    };

    // subscribe to device changing in store
    this.store.subscribe(async (mutation) => {
      if (
        mutation.type == "setLocalSourceAudio" ||
        mutation.type == "setLocalSourceVideo"
      ) {
        console.log("source changed so let's reconfigure local stream");
        await this.configureLocalStream();
      } else if (
        mutation.type == "setLocalSwitchAudio" ||
        mutation.type == "setLocalSwitchVideo"
      ) {
        console.log(`${mutation.type}`);
        await this.updateSwitchState();
      }
    });

    this.connect();
  }

  async getCurrentRtt() {
    let currentRtt = null;
    if (this.pc) {
      let stats = await this.pc.getStats();
      stats.forEach((report) => {
        if (
          report.type == "candidate-pair" &&
          report.nominated &&
          report.state == "succeeded"
        ) {
          currentRtt = report.currentRoundTripTime;
        }
      });
    }
    return currentRtt;
  }

  async join() {
    this.ws.send(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "join",
        params: {
          call_guid: this.guid,
        },
      })
    );
  }

  async onReceiveConfig(config) {
    this.config = config;
    await this.initPC();
    console.log(`pc is configured ${this.pc}`);
    await this.ready();
    this.isReady = true;
    console.log("ready is sent");
  }

  async ready() {
    this.ws.send(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "ready",
        params: {
          call_guid: this.guid,
        },
      })
    );
  }

  async stop() {
    this.store.commit("setStatus", "leaved");
    this.store.commit("setRemoteStream", null);
    this.isReady = false;
    this.store.commit("setIsOpponentJoined", false);
    this.isNegotiated = false;
    this.bufferCandidates = [];
    this.bufferOffer = null;
    await this.configureLocalStream();
    await this.initPC();
    this.isReady = true;
    this.store.commit("setRemoteSwitchAudio", null);
    this.store.commit("setRemoteSwitchVideo", null);
  }

  async onReceiveRole(is_master, reason) {
    this.isMaster = is_master;
    if (reason == "leave") {
      await this.stop();
    } else if (reason == "join") {
      this.store.commit("setStatus", "joined");
      await this.updateSwitchState();
      await this.onReceiveRoleJoined();
      this.store.commit("setIsOpponentJoined", true);
    }
  }

  async onReceiveRoleJoined() {
    if (this.bufferCandidates.length != 0) {
      try {
        this.ws.send(
          JSON.stringify({
            jsonrpc: "2.0",
            method: "addCandidates",
            params: {
              call_guid: this.guid,
              candidates: this.bufferCandidates,
            },
          })
        );
        this.bufferCandidates = [];
      } catch (e) {
        this.fail(e, "onIceCandidate");
      }
    }

    if (this.isMaster) {
      console.log("start the call");
      var offer = await this.pc.createOffer({
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      });
      console.log(`created offer`);
      await this.pc.setLocalDescription(offer);
      this.ws.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "offer",
          params: {
            call_guid: this.guid,
            sdp: offer.sdp,
          },
        })
      );
      console.log("call was started: offer created and sent");
    }
  }

  async onIceCandidate(event) {
    if (!(this.store.state.isOpponentJoined && this.isReady)) {
      if (event.candidate) {
        console.log(`save candidate to buffer ${event.candidate}`);
        this.bufferCandidates.push(event.candidate);
      }
    }

    try {
      this.ws.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "addCandidates",
          params: {
            call_guid: this.guid,
            candidates: [event.candidate],
          },
        })
      );
    } catch (e) {
      this.fail(e, "onIceCandidate");
    }
  }

  async onReceiveOffer(sdp) {
    console.log(`on offer: ${this.pc.signalingState}`);

    const isStable =
      this.pc.signalingState == "stable" ||
      this.pc.signalingState == "have-local-offer";

    console.log(`on offer stable = ${isStable}`);

    const ignoreOffer = this.isMaster && (this.makingOffer || !isStable);

    if (ignoreOffer) {
      console.log(`on offer:  glare - ignoring offer`);
      return;
    }

    console.log("set remote description start");

    try {
      await this.pc.setRemoteDescription(
        new RTCSessionDescription({ type: "offer", sdp: sdp })
      );
    } catch (e) {
      this.fail(e, "setRemoteDescription");
      console.log(`error on setting remote description. reinit pc ${e}`);
      this.store.commit("setStatus", "error");
      return;
    }

    console.log("createAnswer start");

    try {
      const answer = await this.pc.createAnswer();
      console.log(`Answer`); // :\n${answer.sdp}`);
      console.log("pc setLocalDescription start");
      try {
        await this.pc.setLocalDescription(answer);
        console.log("pc setLocalDescription success");
      } catch (e) {
        this.fail(e, "setLocalDescription (answer)");
        console.log(`Failed to set local session description: ${e.toString()}`);
        this.store.commit("setStatus", "error");
      }

      this.ws.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "answer",
          params: {
            call_guid: this.guid,
            sdp: answer.sdp,
          },
        })
      );
    } catch (e) {
      this.fail(e, "createAnswer onReceiveOffer");
      console.log(`Failed to create session description: ${e.toString()}`);
    }
  }

  async onReceiveAnswer(sdp) {
    try {
      if (!this.isMaster && this.bufferOffer) {
        // case for renegotiation
        console.log("set buffered offer");
        await this.pc.setLocalDescription(this.bufferOffer);
        this.bufferOffer = null;
      }
      let desc = new RTCSessionDescription({ type: "answer", sdp: sdp });
      await this.pc.setRemoteDescription(desc);
    } catch (e) {
      this.fail(e, "onReceiveAnswer");
    }
    this.pc.dispatchEvent(new Event("negotiated"));
  }

  async onIceStateChange(event) {
    if (this.pc) {
      console.log(`ICE state changed: ${this.pc.iceConnectionState}`);
      this.store.commit("setStatus", this.pc.iceConnectionState);
      if (event.type == "iceconnectionstatechange") {
        if (this.pc.iceConnectionState == "connected") {
          await this.updateSwitchState();
          this.isNegotiated = true;
        }
      }
    }
  }

  fail(e, ctx) {
    let signalingState = null;
    if (this.pc) {
      signalingState = this.pc.signalingState;
    }
    let msg = `${ctx} | ${signalingState} | ${e.toString()}`;
    console.log(msg);
    this.store.commit("setAlert", {
      // title: "Что-то пошло не так",
      title: "Something gone wrong",
      details: msg,
      icon: "mdi-cancel",
    });
    this.store.commit("setStatus", "error");
  }

  async onNegotiationNeeded(e) {
    console.log(`negotationneeded event: ${e}`);

    if (!this.isNegotiated) {
      console.log("ignore negotationneeded event: not ready yet");
      return;
    }

    if (this.makingOffer) {
      console.log("ignore negotationneeded on making offer");
      return;
    }

    try {
      this.makingOffer = true;

      var offer = await this.pc.createOffer({
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      });

      console.log(`created offer`);
      if (this.isMaster) {
        await this.pc.setLocalDescription(offer);
      } else {
        this.bufferOffer = offer;
      }

      this.ws.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "offer",
          params: {
            call_guid: this.guid,
            sdp: offer.sdp,
          },
        })
      );
    } catch (e) {
      this.fail(e, "onNegotiationNeeded createOffer");
    } finally {
      this.makingOffer = false;
    }
  }

  async initPC() {
    if (!this.store.state.localStream) {
      console.log(
        `dont have local stream in init peer connection so try to get it before creating`
      );
      await this.configureLocalStream();
    }

    if (this.pc) {
      console.log(
        `recreating PC. remove stream ${this.store.state.localStream} from ${this.pc}`
      );
      try {
        this.pc.removeStream(this.store.state.localStream);
      } catch (e) {
        console.log(`can't remove stream: ${e}`);
      }
      this.pc = null;
    }

    let pc = new RTCPeerConnection(this.config);

    console.log("created peer connection");

    pc.addEventListener(
      "icecandidate",
      async (e) => await this.onIceCandidate(e)
    );
    pc.addEventListener(
      "iceconnectionstatechange",
      async (e) => await this.onIceStateChange(e)
    );
    pc.addEventListener(
      "negotiationneeded",
      async (e) => await this.onNegotiationNeeded(e)
    );

    pc.addEventListener("track", async (e) => await this.onGotRemoteStream(e));

    if (this.store.state.localStream) {
      this.store.state.localStream
        .getTracks()
        .forEach(
          async (track) =>
            await pc.addTrack(track, this.store.state.localStream)
        );
    }
    this.store.commit("setStatus", "waiting");
    this.pc = pc;
  }

  async onGotRemoteStream(e) {
    console.log(`got remote stream: ${e.streams}`);
    console.log(e.streams[0]);
    console.log(e);
    if (this.store.state.remoteStream !== e.streams[0]) {
      this.store.commit("setRemoteStream", e.streams[0]);
    }
    // if ((!this.store.state.remoteStream) || (this.store.state.remoteStream.id != e.streams[0].id)) {
    //
    // }
  }

  async onReceiveState(state) {
    console.log(`received remote state: ${state}`);
    if (this.store.state.remoteSwitchAudio != state.audio) {
      this.store.commit("setRemoteSwitchAudio", state.audio);
    }
    if (this.store.state.remoteSwitchVideo != state.video) {
      this.store.commit("setRemoteSwitchVideo", state.video);
    }
  }

  async onReceiveCandidates(candidates) {
    for (const candidate of candidates) {
      if (candidate != null) {
        let c = new RTCIceCandidate(candidate);
        console.log(`try to apply ice candidate`);
        await this.pc.addIceCandidate(c);
      } else {
        console.log("received null candidate");
        // await this.pc.addIceCandidate(new RTCIceCandidate(null));
      }
    }
  }

  async onError(type) {
    if (type == "RoomIsFull") {
      this.store.commit("setAlert", {
        // title: "Подключение отклонено — комната уже заполнена",
        title: "Room is overfilled",
        details: null,
        icon: "mdi-close-circle",
      });
    } else {
      this.store.commit("setAlert", {
        // title: `Неизвестная ошибка ${type}`,
        title: `Unknown error ${type}`,
        details: null,
        icon: "mdi-close-circle",
      });
    }
  }

  async onMessage(evt) {
    var msg = JSON.parse(evt.data);
    if (msg.params.call_guid != this.guid) {
      alert(evt.data);
    }
    console.log(`received ${msg.method}`);
    if (msg.method == "offer") {
      await this.onReceiveOffer(msg.params.sdp);
    } else if (msg.method == "answer") {
      await this.onReceiveAnswer(msg.params.sdp);
    } else if (msg.method == "addCandidates") {
      await this.onReceiveCandidates(msg.params.candidates);
    } else if (msg.method == "role") {
      await this.onReceiveRole(msg.params.is_master, msg.params.reason);
    } else if (msg.method == "config") {
      await this.onReceiveConfig(msg.params.config);
    } else if (msg.method == "state") {
      await this.onReceiveState(msg.params.state);
    } else if (msg.method == "error") {
      await this.onError(msg.params.type);
    }
  }

  connect() {
    // strange js logic
    var call = this;
    this.ws = new WebSocket(this.path);
    this.ws.onmessage = async function(evt) {
      call.onMessage(evt);
    };
    this.ws.onopen = async function() {
      call.store.commit("setWsConnected", true);
      await call.join();
    };
    this.ws.onclose = async function(e) {
      call.store.commit("setWsConnected", false);
      console.log(
        "Socket is closed. Reconnect will be attempted in 5 seconds",
        e.reason
      );
      await call.stop();
      setTimeout(function() {
        call.connect();
      }, 5000);
    };
  }

  async getStream() {
    let cfg = {
      audio: {
        deviceId: this.store.state.localSourceAudio
          ? { exact: this.store.state.localSourceAudio.deviceId }
          : undefined,
      },
      video: {
        deviceId: this.store.state.localSourceVideo
          ? { exact: this.store.state.localSourceVideo.deviceId }
          : undefined,
      },
    };
    return await navigator.mediaDevices.getUserMedia(cfg);
  }

  async configureLocalStream() {
    console.log("Requesting local stream");
    try {
      let stream = null;
      try {
        stream = await this.getStream();
      } catch (e) {
        this.store.commit("setAlert", {
          // title: "Нет разрешения на использование камеры",
          title: "No permission for video input",
          details: e.toString(),
          icon: "mdi-close-circle",
        });
        return;
      }

      console.log(`local stream is ${stream}`);
      // stop each track if it already exists

      if (this.store.state.localStream) {
        console.log("stop all tracks from existed localStream");
        this.store.state.localStream.getTracks().forEach((track) => {
          track.stop();
        });
        if (this.pc) {
          console.log(
            `remove stream ${this.store.state.localStream} from ${this.pc}`
          );
          try {
            this.pc.removeStream(this.store.state.localStream);
          } catch (e) {
            console.log(`can't remove stream: ${e}`);
          }
        }
      }

      this.store.commit("setLocalStream", stream);

      if (this.pc) {
        console.log("Add tracks into peer connection");
        this.store.state.localStream
          .getTracks()
          .forEach(
            async (track) =>
              await this.pc.addTrack(track, this.store.state.localStream)
          );
      }
    } catch (e) {
      this.store.commit("setStatus", "error");
      this.fail(e, "configure localStream");
    }
    console.log("Local stream configured");
  }

  async captureLocalDevices() {
    let devices = await navigator.mediaDevices.enumerateDevices();
    this.store.commit("setLocalDevices", devices);
    if (
      this.store.state.localSourceAudio == null &&
      this.store.getters.audioInputs
    ) {
      this.store.commit(
        "setLocalSourceAudio",
        this.store.getters.audioInputs[0]
      );
    }
    if (
      this.store.state.localSourceVideo == null &&
      this.store.getters.videoInputs
    ) {
      this.store.commit(
        "setLocalSourceVideo",
        this.store.getters.videoInputs[0]
      );
    }
    if (
      this.store.state.localOutputAudio == null &&
      this.store.getters.audioOutputs
    ) {
      this.store.commit(
        "setLocalOutputAudio",
        this.store.getters.audioOutputs[0]
      );
    }
  }

  checkConnectedSources() {
    let audioDeviceId = this.store.state.localSourceAudio
      ? this.store.state.localSourceAudio.deviceId
      : null;
    let audioDeviceIds = this.store.getters.audioInputs.map(
      (device) => device.deviceId
    );
    if (!audioDeviceIds.includes(audioDeviceId)) {
      console.log(
        "localSourceAudio was disconnected so reset audio input to first in list"
      );
      this.store.dispatch("resetLocalSourceAudio");
    }
    let videoDeviceId = this.store.state.localSourceVideo
      ? this.store.state.localSourceVideo.deviceId
      : null;
    let videoDeviceIds = this.store.getters.videoInputs.map(
      (device) => device.deviceId
    );
    if (!videoDeviceIds.includes(videoDeviceId)) {
      console.log(
        "localSourceVideo was disconnected so reset video input to first in list"
      );
      this.store.dispatch("resetLocalSourceVideo");
    }
  }

  async updateSwitchState() {
    let audio = this.store.state.localSwitchAudio;
    let video = this.store.state.localSwitchVideo;

    if (this.store.state.localStream) {
      this.store.state.localStream.getAudioTracks()[0].enabled = audio;
      this.store.state.localStream.getVideoTracks()[0].enabled = video;
    }

    if (this.store.state.isOpponentJoined) {
      this.ws.send(
        JSON.stringify({
          jsonrpc: "2.0",
          method: "state",
          params: {
            call_guid: this.guid,
            state: {
              audio: audio,
              video: video,
            },
          },
        })
      );
    } else {
      console.log(
        "ignore changing of switches due to opposite participant is not connected yet"
      );
    }
  }

  constructor(store, path) {
    this.path = path;
    this.store = store;

    this.config = null;
    this.pc = null;
    this.guid = null;

    this.isMaster = false;
    this.makingOffer = false;

    this.isReady = false;
    this.isNegotiated = false;

    this.bufferCandidates = [];
    this.bufferOffer = null;

    store.commit("setCall", this);
  }
}

export default Call;
