<template>
  <div>
    <v-app-bar v-if="!isHome" dense dark app>
      <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn>
      <!-- <v-toolbar-title>qwe</v-toolbar-title> -->
    </v-app-bar>

    <v-footer app inset fixed dark padless>
      <v-btn class="disable-events" icon v-if="remoteStream">
        <v-icon :color="signalStrengthColor">mdi-download-network</v-icon>
      </v-btn>
      <v-btn class="disable-events" icon v-else>
        <v-icon color="grey">mdi-download-network</v-icon>
      </v-btn>
      <v-btn class="disable-events" icon v-if="remoteSwitchAudio != null">
        <v-icon v-if="remoteSwitchAudio == true" color="green"
          >mdi-microphone</v-icon
        >
        <v-icon v-if="remoteSwitchAudio == false" color="red"
          >mdi-microphone-off</v-icon
        >
      </v-btn>
      <v-btn class="disable-events" icon v-if="remoteSwitchVideo != null">
        <v-icon v-if="remoteSwitchVideo == true" color="green"
          >mdi-camcorder</v-icon
        >
        <v-icon v-if="remoteSwitchVideo == false" color="red"
          >mdi-camcorder-off</v-icon
        >
      </v-btn>

      <!-- <v-spacer></v-spacer> -->

      <span class="overline align-right" style="margin-left: 5px">
        {{ timerDate }}
      </span>

      <v-spacer></v-spacer>
      <!-- uncomment for debug  -->
      <!-- <span class="overline">{{ status }}</span> -->
      <!-- <span class="caption" v-if="signalingState">{{ signalingState }} </span> -->
      <!-- <span v-if="currentRtt != null">rtt: {{ currentRtt * 1000 }}ms</span> -->

      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon color="green">mdi-share-variant</v-icon>
          </v-btn>
        </template>
        <Share />
      </v-dialog>

      <v-btn icon @click="switchAudio = !switchAudio">
        <v-icon v-if="switchAudio" color="green">mdi-microphone</v-icon>
        <v-icon v-else color="red">mdi-microphone-off</v-icon>
      </v-btn>
      <v-btn icon @click="switchVideo = !switchVideo">
        <v-icon v-if="switchVideo" color="green">mdi-camcorder</v-icon>
        <v-icon v-else color="red">mdi-camcorder-off</v-icon>
      </v-btn>
      <Settings />
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Settings from "./Settings.vue";
import Share from "./Share.vue";

export default {
  components: {
    Settings,
    Share,
  },
  async mounted() {
    await this.startWatcher();
  },
  data() {
    return {
      dialog: false,
      currentRtt: null,

      // timer: null,
      timerID: -1,
      timerSeconds: 0,
      timerDate: null,
    };
  },
  watch: {
    isOpponentJoined: {
      immediate: true,
      handler(val) {
        function secondsToDate(seconds) {
          var date = new Date(0);
          date.setSeconds(seconds);
          return date.toISOString().substr(11, 8);
        }

        let view = this;

        function tick() {
          view.timerSeconds += 1;
          view.timerDate = secondsToDate(view.timerSeconds);
        }

        if (val == true) {
          this.timerID = setInterval(tick, 1000);
        } else {
          this.timerSeconds = 0;
          this.timerDate = null;
          clearInterval(this.timerID);
        }
      },
    },
  },
  methods: {
    async startWatcher() {
      let view = this;
      async function startStatsWatcher() {
        async function iteration() {
          setTimeout(iteration, 1000);
          let call = view.$store.state.call;
          if (!call || !call.isNegotiated) {
            return;
          }
          let currentRtt = await call.getCurrentRtt();
          if (currentRtt == null) {
            return;
          }
          view.currentRtt = currentRtt;
        }
        iteration();
      }
      startStatsWatcher();
    },
  },
  computed: {
    ...mapState([
      "status",
      "remoteSwitchAudio",
      "remoteSwitchVideo",
      "localSwitchAudio",
      "localSwitchVideo",
      "remoteStream",
      "isOpponentJoined",
    ]),
    isHome() {
      return this.$route.path === "/";
    },
    webShareApiSupported() {
      return navigator.share;
    },
    guid() {
      return this.$store.state.call.guid;
    },
    currentUrl() {
      return window.location.href;
    },
    signalingState() {
      if (this.$store.state.call) {
        if (this.$store.state.call.pc) {
          return this.$store.state.call.pc.signalingState;
        }
      }
      return null;
    },
    signalStrength() {
      function getPercentFromRtt(rttMs) {
        let minRtt = 5;
        let maxRtt = 1000;
        if (rttMs <= minRtt) {
          return 100;
        } else if (minRtt <= rttMs && rttMs < maxRtt) {
          return 100 - (rttMs / maxRtt) * 100;
        } else if (rttMs >= maxRtt) {
          return 0;
        }
        return null;
      }
      if (this.currentRtt == null) {
        return null;
      }
      return getPercentFromRtt(this.currentRtt * 1000);
    },
    signalStrengthColor() {
      let value = this.signalStrength;
      if (value == null) {
        return null;
      }
      if (90 < value && value <= 100) {
        return "green";
      } else if (80 < value && value <= 90) {
        return "light-green";
      } else if (70 < value && value <= 80) {
        return "lime";
      } else if (60 < value && value <= 70) {
        return "yellow";
      } else if (50 < value && value <= 60) {
        return "amber";
      } else if (40 < value && value <= 50) {
        return "orange";
      } else if (0 <= value && value <= 40) {
        return "red";
      }
      return null;
    },
    switchAudio: {
      get() {
        return this.localSwitchAudio;
      },
      set(value) {
        this.$store.commit("setLocalSwitchAudio", value);
      },
    },
    switchVideo: {
      get() {
        return this.localSwitchVideo;
      },
      set(value) {
        this.$store.commit("setLocalSwitchVideo", value);
      },
    },
  },
};
</script>
<style scoped>
.disable-events {
  pointer-events: none;
}
</style>