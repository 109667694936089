<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="headline"> {{ $t("share") }} </v-card-title>
        <v-card-text>
          <p>
            {{ $t("toConnect") }}
          </p>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ guid }}</b></v-list-item-title
                >
                {{ $t("callID") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><b>{{ currentUrl }}</b></v-list-item-title
                >
                {{ $t("link") }}
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-btn outlined block v-clipboard="currentUrl">
                  Скопировать ссылку<v-icon>mdi-share-variant</v-icon></v-btn
                >
              </v-list-item-content>
            </v-list-item> -->
            <div v-if="webShareApiSupported">
              <v-btn outlined block @click="shareViaWebShare" color="green">
                {{ $t("share") }}
              </v-btn>
            </div>
            <br />
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      en: {
        toConnect: "Share link to this page to people you want to connect with",
        callID: "Call ID",
        link: "Link to call",
        share: "Share",
      },
      ru: {
        toConnect:
          "Чтобы к звонку мог присоединиться другой человек — просто отправьте ему ссылку на текущую страницу.",
        callID: "Идентификатор звонка",
        link: "Ссылка на звонок",
        share: "Поделиться",
      },
    },
  },
  computed: {
    webShareApiSupported() {
      return navigator.share;
    },
    guid() {
      return this.$store.state.call.guid;
    },
    currentUrl() {
      return window.location.href;
    },
  },
  methods: {
    shareViaWebShare() {
      navigator.share({
        title: "Link2CallMe",
        // text: "Text to be shared",
        url: this.currentUrl,
      });
    },
  },
};
</script>
