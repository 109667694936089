<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark v-bind="attrs" v-on="on">
        <div v-if="localStream">
          <v-icon color="green">mdi-upload-network</v-icon>
        </div>
        <div v-else>
          <v-icon color="grey">mdi-upload-network</v-icon>
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"> {{ $t("settings") }} </v-card-title>
      <v-card-text>
        <v-list>
          <v-select
            item-text="label"
            item-value="deviceId"
            v-model="audioInput"
            :label="$t('audioInput')"
            :items="audioInputs"
            :disabled="audioInputs.length <= 1"
          />
          <v-select
            item-text="label"
            item-value="deviceId"
            v-model="videoInput"
            :label="$t('videoInput')"
            :items="videoInputs"
            :disabled="videoInputs.length <= 1"
          />
          <v-select
            item-text="label"
            item-value="deviceId"
            v-model="audioOutput"
            :label="$t('audioOutput')"
            :items="audioOutputs"
            :disabled="audioOutputs.length <= 1"
          />
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  i18n: {
    messages: {
      en: {
        settings: "Settings",
        audioInput: "Audio input",
        videoInput: "Video input",
        audioOutput: "Audio output",
      },
      ru: {
        settings: "Настройки",
        audioInput: "Микрофон",
        videoInput: "Камера",
        audioOutput: "Динамик",
      },
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "audioInputs",
      "videoInputs",
      "audioOutputs",
      "localDevices",
    ]),
    ...mapState([
      "localSourceAudio",
      "localSourceVideo",
      "localOutputAudio",
      "localSwitchAudio",
      "localSwitchVideo",
      "localStream",
    ]),
    showAudioOutputSetting() {
      // can we change audio destination
      return "sinkId" in HTMLMediaElement.prototype;
    },
    audioInput: {
      get() {
        if (!this.localSourceAudio) {
          return null;
        }
        return this.localSourceAudio.deviceId;
      },
      set(deviceId) {
        this.$store.commit(
          "setLocalSourceAudio",
          this.audioInputs.filter((d) => d.deviceId == deviceId)[0]
        );
      },
    },
    videoInput: {
      get() {
        if (!this.localSourceVideo) {
          return null;
        }
        return this.localSourceVideo.deviceId;
      },
      set(deviceId) {
        this.$store.commit(
          "setLocalSourceVideo",
          this.videoInputs.filter((d) => d.deviceId == deviceId)[0]
        );
      },
    },
    audioOutput: {
      get() {
        if (!this.localOutputAudio) {
          return null;
        }
        return this.localOutputAudio.deviceId;
      },
      set(deviceId) {
        this.$store.commit(
          "setLocalOutputAudio",
          this.audioOutputs.filter((d) => d.deviceId == deviceId)[0]
        );
      },
    },
    switchAudio: {
      get() {
        return this.localSwitchAudio;
      },
      set(value) {
        this.$store.commit("setLocalSwitchAudio", value);
      },
    },
    switchVideo: {
      get() {
        return this.localSwitchVideo;
      },
      set(value) {
        this.$store.commit("setLocalSwitchVideo", value);
      },
    },
  },
};
</script>
