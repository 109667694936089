<template>
  <div>
    <RemoteVideo v-if="remoteStream" />
    <div v-else>
      <div v-if="status != 'waiting'">
        <Waiting />
      </div>
    </div>
  </div>
</template>

<script>
import RemoteVideo from "./RemoteVideo.vue";
import Waiting from "./Waiting.vue";

import { mapState } from "vuex";
export default {
  components: {
    RemoteVideo,
    Waiting,
  },
  computed: {
    ...mapState(["status", "remoteStream", "isWsConnected", "remoteStream"]),
    showDiclaimer() {
      return (
        this.isWsConnected && (this.status == "waiting" || this.status == "new")
      );
    },
  },
};
</script>
<style scoped>
div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0.5;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>