<template>
  <v-container class="grey lighten-5">
    <Banner />
    <LocalVideo />
    <MainScreen />
    <Status />
  </v-container>
</template>

<script>
import Banner from "@/components/Banner.vue";
import LocalVideo from "@/components/LocalVideo.vue";
import Status from "@/components/Status.vue";
import MainScreen from "@/components/MainScreen.vue";
import Call from "@/store/call.js";

export default {
  name: "Call",
  components: {
    Banner,
    LocalVideo,
    MainScreen,
    Status,
  },
  async mounted() {
    let path = "";

    if (location.host == "localhost:8080") {
      path = "ws://localhost:8888/ws";
    } else if (window.location.protocol == "https:") {
      path = "wss://" + location.host + "/ws";
    } else {
      path = "ws://" + location.host + "/ws";
    }

    this.$store.commit("setCall", new Call(this.$store, path));

    await this.$store.state.call.start();
  },
};
</script>

<style>
html {
  /* background: #021521; */
  max-height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
