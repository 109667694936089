<template>
  <div id="remote">
    <video ref="video" playsinline autoplay :srcObject.prop="remoteStream" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState(["localOutputAudio", "remoteStream"]),
  watch: {
    localOutputAudio: {
      immediate: true,
      handler(val, oldVal) {
        if (val == null) {
          return;
        }
        this.attachSinkId(val.deviceId);
        console.log(`audio was changed from ${oldVal} to ${val}`);
      },
    },
  },
  methods: {
    attachSinkId(sinkId) {
      if (!this.$refs.video) {
        console.log("cant set audio output to empty video element");
        return;
      }
      this.$refs.video
        .setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error) => {
          let errorMessage = error;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          this.$store.dispatch("resetLocalOutputAudio");
        });
    },
  },
};
</script>


<style scoped>
video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 0;
}
</style>