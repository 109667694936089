<template>
  <v-card>
    <v-banner v-model="show" elevation="24">
      <v-icon slot="icon" color="warning" size="36">
        {{ alertIcon }}
      </v-icon>

      <div v-if="alertDetails">
        <v-expansion-panels elevation="0">
          <v-expansion-panel elevation="0">
            <v-expansion-panel-header>
              {{ alertTitle }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <code>
                {{ alertDetails }}
              </code>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div v-else>
        {{ alertTitle }}
      </div>

      <template v-slot:actions="{ dismiss }">
        <v-btn color="primary" text @click="dismiss">
          {{ $t("hide") }}
        </v-btn>
        <v-btn color="primary" text @click="alert"> {{ $t("reload") }} </v-btn>
      </template>
    </v-banner>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  i18n: {
    messages: {
      en: {
        hide: "Hide",
        reload: "Reload",
      },
      ru: {
        hide: "Скрыть",
        reload: "Перезагрузить",
      },
    },
  },
  computed: {
    ...mapState(["alertTitle", "alertDetails", "alertIcon"]),
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    alertTitle: {
      immediate: true,
      handler(val) {
        if (val == null) {
          return;
        }
        this.show = true;
      },
    },
  },
  methods: {
    alert() {
      location.reload(true);
    },
  },
};
</script>

<style scoped>
div {
  z-index: 10000;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>