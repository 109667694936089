<template>
  <v-row align="center" justify="center">
    <v-col align="center" justify="center">
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="green"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["isWsConnected"]),
};
</script>

<style scoped>
#waiting {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>