<template>
  <div>
    <video
      v-if="localStream"
      playsinline
      autoplay
      muted
      :srcObject.prop="localStream"
    ></video>
    <p v-else>No video</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState(["localStream"]),
};
</script>


<style scoped>
video {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #000000;

  /* mirror */
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */

  width: 20%;
  border: 1px ridge rgba(25, 25, 25, 0.25);
  border-radius: 5px;

  /* shadow */
  -webkit-box-shadow: 0px 0px 5px 0px rgba(20, 20, 20, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(20, 20, 20, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(20, 20, 20, 0.5);

  z-index: 100;

  /* safari specific for correct z-index working*/
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
</style>